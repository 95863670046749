document.addEventListener('DOMContentLoaded', () => {
  const button = document.getElementById('party') as HTMLButtonElement;
  const images = document.querySelectorAll('.partyHat'); // Select all images with class "partyHat"

  if (!button || images.length === 0) return;

  // Check sessionStorage for visibility state
  const isVisible = sessionStorage.getItem('party') === 'true';

  // Apply visibility state to all images
  images.forEach((image) => {
    (image as HTMLElement).setAttribute('data-party', isVisible.toString());
  });

  // Button click event
  button.addEventListener('click', () => {
    // Determine new visibility state (toggle from the first image)
    const currentlyVisible = images[0].getAttribute('data-party') === 'true';
    const newState = !currentlyVisible;

    // Apply new state to all images
    images.forEach((image) => {
      (image as HTMLElement).setAttribute('data-party', newState.toString());
    });

    // Store new state in sessionStorage
    sessionStorage.setItem('party', String(newState));
  });
});
