// Helper functions

function toggleClass(element: HTMLElement, classes: string[]) {
  classes.forEach((cls) => element.classList.toggle(cls));
}

function toggleDataAttribute(element: HTMLElement, attr: string) {
  const isOpen = element.getAttribute(attr) == 'true';
  element.setAttribute(attr, (!isOpen).toString());
  return !isOpen;
}

function setupClickAndTab(element: HTMLElement, callback: () => void) {
  element.addEventListener('click', callback);
  element.addEventListener('keydown', (e) => {
    if (e.key === 'Tab') {
      callback();
    }
  });
}

// Main mobile Menu with tab and click support
const menuTrigger = document.getElementById('menu-trigger') as HTMLElement;
const menuElement = document.getElementById('menu') as HTMLElement;
let currentVisibleItem: HTMLElement | null = null;

if (menuTrigger && menuElement) {
  menuTrigger.addEventListener('click', toggleMenu);

  function toggleMenu() {
    toggleDataAttribute(menuElement, 'data-open');
    toggleClass(document.body, ['h-dvh', 'overflow-hidden']);
    toggleDataAttribute(menuTrigger, 'aria-expanded');
  }

  // Close the menu when tabbing outside
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Tab') {
      if (
        menuElement.getAttribute('data-open') == 'true' &&
        !menuElement.contains(event.target as Node) &&
        currentVisibleItem !== null
      ) {
        menuElement.setAttribute('data-open', 'false');
      }
    }
  });

  menuTrigger.addEventListener('keydown', function (event) {
    if (event.key === 'Tab') {
      if (menuElement) {
        toggleDataAttribute(menuElement, 'data-open');
      }
    }
  });
}

const tabTriggers = document.querySelectorAll<HTMLElement>('.tab-triggers');

tabTriggers.forEach((tabTrigger) => {
  tabTrigger.addEventListener('keydown', function (event) {
    if (event.key === 'Tab') {
      let item: HTMLElement | null = this.querySelector('.tab-content')
        ? (this.querySelector('.tab-content') as HTMLElement)
        : (this.nextElementSibling as HTMLElement);
      if (item) {
        item.setAttribute('data-open', 'true');
        currentVisibleItem = item;
        item.addEventListener('focusout', function (event) {
          // Close the tab content if the focus is outside the tab content
          if (!item.contains(event.relatedTarget as Node)) {
            item.setAttribute('data-open', 'false');
          }
        });
      }
    }
    // Close using escape key
    if (event.key === 'Escape') {
      currentVisibleItem?.setAttribute('data-open', 'false');
    }
  });
});

const pillarParent = document.getElementById('pillar-parent');
const pillars = document.querySelectorAll<HTMLElement>('.pillar');
const firstPillarChild = document.querySelector('.pillar-child') as HTMLElement;
let openedPillar: HTMLElement = firstPillarChild;

// Show first one on load
firstPillarChild?.setAttribute('data-open', 'true');

// Show first one on hover of parent
pillarParent?.addEventListener('click', () => {
  toggleDataAttribute(pillarParent, 'data-open');
  if (openedPillar !== firstPillarChild) {
    openedPillar?.setAttribute('data-open', 'false');
    firstPillarChild?.setAttribute('data-open', 'true');
    openedPillar = firstPillarChild;
  }
});

pillars.forEach((pillar) => {
  pillar.addEventListener('mouseenter', function () {
    if (pillar !== openedPillar) {
      // Close previously opened
      openedPillar?.setAttribute('data-open', 'false');

      // Open current
      pillar.setAttribute('data-open', 'true');
      openedPillar = pillar;
    }
  });
});

// Mobile
const mobileServiceTrigger = document.getElementById(
  'mobile-service-menu'
) as HTMLElement;

const mobilePillars = document.querySelectorAll<HTMLElement>('.mobile-pillar');

mobileServiceTrigger?.addEventListener('click', () =>
  toggleDataAttribute(mobileServiceTrigger, 'data-open')
);

mobilePillars.forEach((pillar) => {
  pillar.addEventListener('click', () =>
    toggleDataAttribute(pillar, 'data-open')
  );
  const children = pillar.querySelectorAll<HTMLElement>('.mobile-pillar-child');
  children.forEach((child) => {
    child.addEventListener('click', () =>
      toggleDataAttribute(child, 'data-open')
    );
  });
});
